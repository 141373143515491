import { Layout, theme } from "antd";
import { Content, Header, Footer } from "antd/es/layout/layout";
import LoginPage from "./Pages/Login";
import { createContext, useState } from "react";
import UsersPage from "./Pages/Users";
import Api from "./Api";

export const UserContext = createContext();
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const setLoginKey = (key) => {
    Api.data.keyEncoded = key;
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={{ color: "white" }}>
        <span style={{ fontSize: "32px", verticalAlign: "sub" }}>🎃</span> Mo
        Chcker Control Panel
      </Header>
      <Content
        style={{
          padding: "48px",
          overflowY: "auto",
        }}
      >
        {isLoggedIn ? (
          <div
            style={{
              background: colorBgContainer,
              padding: 24,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
            <UsersPage />
          </div>
        ) : (
          <LoginPage setIsLoggedIn={setIsLoggedIn} setKey={setLoginKey} />
        )}
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Private Website for Mohelm97, if you find it please email{" "}
        <a href="mailto:mohelm97@gmail.com">mohelm97@gmail.com</a> :D
      </Footer>
    </Layout>
  );
}
export const A = 123;

export default App;
