const apiBase = "api";
let data = { keyEncoded: "" };
const headers = {
  "content-type": "application/json",
};
const checkLoginKey = (key) => {
  return fetch(
    `${apiBase}/manage/check-key?akey=${encodeURIComponent(key)}`
  ).then((res) => res.json());
};
const getUsers = () => {
  return fetch(`${apiBase}/manage/get-users?akey=${data.keyEncoded}`, {
    headers,
  }).then((res) => res.json());
};
const editUser = (user) => {
  return fetch(
    `${apiBase}/manage/update-user/${user.id}?akey=${data.keyEncoded}`,
    {
      method: "post",
      body: JSON.stringify({
        is_active: user.is_active,
        nickname: user.nickname,
        inject_command: user.inject_command,
      }),
      headers,
    }
  ).then((res) => res.json());
};
const deleteUser = (userId) => {
  return fetch(
    `${apiBase}/manage/delete-user/${userId}?akey=${data.keyEncoded}`,
    {
      headers,
      method: "delete",
    }
  ).then((res) => res.json());
};
const getLogs = (userId) => {
  return fetch(`${apiBase}/manage/get-logs/${userId}?akey=${data.keyEncoded}`, {
    headers,
  }).then((res) => res.json());
};
const Api = {
  apiBase,
  checkLoginKey,
  getUsers,
  editUser,
  deleteUser,
  getLogs,
  data,
};
export default Api;
