import {
  Form,
  Input,
  Modal,
  Spin,
  Switch,
  Table,
  message,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import Api from "../../Api";
import Link from "antd/es/typography/Link";
import LogsModal from "./LogsModal";

function UsersPage() {
  const [users, setUsers] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [logsModalOpen, setLogsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const editUser = (record) => {
    form.setFieldsValue(record);
    setSelectedRecord(record);
    setEditModalOpen(true);
  };
  const setUserActive = async (record, value) => {
    record.is_active = value;
    setUsers([...users]);
    try {
      const res = await Api.editUser(record);
      if (res.statusCode === 200) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    } catch {
      message.error("Couldn't update user!");
    }
  };
  const showUserLogs = (record) => {
    setSelectedRecord(record);
    setLogsModalOpen(true);
  };
  const deleteUser = async (userId) => {
    try {
      await Api.deleteUser(userId);
      setUsers(users.filter((user) => user.id !== userId));
      message.success("User deleted!");
    } catch {
      message.error("Couldn't delete user");
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      render: (nickname, record) => (
        <Link onClick={() => editUser(record)}>{nickname}</Link>
      ),
    },
    { title: "PC Key", dataIndex: "pc_key" },
    { title: "MAC", dataIndex: "mac" },
    { title: "Processor", dataIndex: "processor" },
    { title: "Volume", dataIndex: "volume" },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) => setUserActive(record, checked)}
        />
      ),
    },
    {
      title: "Logs",
      render: (_, record) => (
        <Link onClick={() => showUserLogs(record)}>Logs</Link>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this user?"
          onConfirm={() => deleteUser(record.id)}
        >
          <Link>Delete</Link>
        </Popconfirm>
      ),
    },
  ];
  const onModalCancel = () => {
    setEditModalOpen(false);
  };
  const onModalOk = async () => {
    setIsModalLoading(true);
    try {
      await form.validateFields();
      const newNickname = form.getFieldValue("nickname");
      const temp = { ...selectedRecord };
      temp.nickname = newNickname;
      const res = await Api.editUser(temp);
      if (res.statusCode === 200) {
        message.success(res.message);
        selectedRecord.nickname = temp.nickname;
        setEditModalOpen(false);
      } else {
        message.error(res.message);
      }
    } catch {
      message.error("Couldn't update nickname!");
    }
    setIsModalLoading(false);
  };
  useEffect(() => {
    Api.getUsers()
      .then((users) => {
        setUsers(users);
      })
      .catch((res) => {
        message.error(res);
      });
  }, []);
  return (
    <>
      <Table bordered columns={columns} dataSource={users} rowKey="id" />
      <Modal
        open={editModalOpen}
        title="Edit PC Nickname"
        onCancel={onModalCancel}
        onOk={onModalOk}
        confirmLoading={isModalLoading}
      >
        <Spin spinning={isModalLoading}>
          <Form form={form} name="editNicknameForm" onFinish={onModalOk}>
            <Form.Item
              label="Nickname"
              name="nickname"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <LogsModal
        isOpen={logsModalOpen}
        setIsOpen={setLogsModalOpen}
        userId={selectedRecord?.id}
      />
    </>
  );
}
export default UsersPage;
