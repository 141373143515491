import { Button, Col, Form, Input, Row, message, theme } from "antd";
import { useState } from "react";
import Api from "../../Api";

function LoginPage({ setIsLoggedIn, setKey }) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = (data) => {
    setIsLoading(true);
    Api.checkLoginKey(data.loginKey)
      .then((json) => {
        if (json.statusCode === 200) {
          setIsLoggedIn(true);
          setKey(data.loginKey);
        } else {
          message.error(json.message);
        }
      })
      .catch((res) => {
        message.error("Something went wrong!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Row align="middle" justify="center" style={{ height: "100%" }}>
      <Col span="12" style={{ maxWidth: 600 }}>
        <Form
          name="loginForm"
          requiredMark={false}
          onFinish={onFinish}
          style={{
            padding: "48px",
            backgroundColor: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Form.Item
            label="Login Key"
            name="loginKey"
            style={{
              textAlign: "center",
            }}
          >
            <Input.Password disabled={isLoading} />
          </Form.Item>
          <Form.Item style={{ textAlign: "center", marginBottom: "0" }}>
            <Button type="primary" loading={isLoading} htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default LoginPage;
