import { Flex, Form, Modal, Select, Space, Switch, Table, message } from "antd";
import { useEffect, useState } from "react";
import Api from "../../Api";

function LogsModal({ userId, isOpen, setIsOpen }) {
  const [logs, setLogs] = useState([]);
  const [filterdLogs, setFilterdLogs] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("all");
  const [mergeByCount, setMergeByCount] = useState(true);
  const columns = [
    { title: "#", dataIndex: "index", width: "0" },
    { title: "Model", dataIndex: "model", width: "0" },
    { title: "Btn Count", dataIndex: "btn_count", width: "10em" },
    { title: "Ok Count", dataIndex: "ok_count", width: "10em" },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      render: (date) => new Date(date).toLocaleString(),
    },
  ];
  useEffect(() => {
    Api.getLogs(userId)
      .then((res) => {
        const tempModels = {};
        res = res.reverse();
        let i = 1;
        for (let log of res) {
          tempModels[log.model] = 1;
          log.index = i;
          i++;
        }
        setModels([
          { value: "all", label: "All" },
          ...Object.keys(tempModels).map((v) => ({ value: v, label: v })),
        ]);
        setLogs(res);
      })
      .catch((res) => {
        message.error("Couldn't get data!");
      });
  }, [userId, isOpen]);
  useEffect(() => {
    const filtered =
      selectedModel === "all"
        ? logs
        : logs.filter((e) => e.model === selectedModel);
    if (mergeByCount) {
      const result = [];
      const map = new Map();
      for (const item of filtered) {
        const key = item.model + ":" + item.btn_count + ":" + item.ok_count;
        if (!map.has(key)) {
          map.set(key, true); // set any value to Map
          result.push(item);
        }
      }
      setFilterdLogs(result);
    } else {
      setFilterdLogs(filtered);
    }
  }, [selectedModel, mergeByCount, logs]);

  const hideModal = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      width="50em"
      open={isOpen}
      okText=""
      title="User Logs"
      onCancel={hideModal}
      onOk={hideModal}
      footer={(_, { OkBtn, CancelBtn }) => (
        <Flex justify="space-between">
          <Space>
            Merge by counts
            <Switch checked={mergeByCount} onChange={setMergeByCount} />
          </Space>
          <OkBtn />
        </Flex>
      )}
    >
      <Table
        footer={() => (
          <Form.Item label="Model" style={{ margin: 0 }}>
            <Select
              options={models}
              value={selectedModel}
              onChange={setSelectedModel}
            />
          </Form.Item>
        )}
        pagination={{ pageSize: 5 }}
        bordered
        columns={columns}
        dataSource={filterdLogs}
        rowKey="id"
      />
    </Modal>
  );
}
export default LogsModal;
